import { useAuthStore } from "../store/app/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  
  const authStore = useAuthStore();

  if (!authStore.isBooted) {
    await authStore.boot();
  }

  if (!authStore.isLoggedIn) {
    return navigateTo("/login");
  }

  return;

});
